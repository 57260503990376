import React, { useEffect, useState } from 'react';
import NotFound from './NotFound';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import AboutUs from './AboutUs';
import Disclaimer from './disclaimer';
import Navbar from './Navbar';
import ContentWrapper from './ContentWrapper';
import Quiz from './Quiz';
import TcseQuiz from './tcseQuiz';
import RcQuiz from './rcQuiz';
import SatMathQuiz from './satmathQuiz';
import SynonymsQuiz from './synonymsQuiz';
import VocabularyBuilder from './vocabularyBuilder';
import MathQuizData from './mathQuiz';
import SatrwQuiz from './satrwQuiz';
import ContactForm from './ContactForm';
import Login from './Login';
import ProfilePage from './ProfilePage';
import Home from './Home';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'bootstrap/dist/css/bootstrap-utilities.min.css';

function App() {
  const [user, setUser] = useState(null); // State to hold the user object
  const [loading, setLoading] = useState(true); // State to handle loading state

  useEffect(() => {
    // Set up an observer on the Auth object to get user state changes
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user); // User is signed in
      } else {
        setUser(null); // No user is signed in
      }
      setLoading(false); // Set loading to false once auth state is determined
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Show a loading spinner or placeholder while the auth state is being determined
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <div className="App">
        <Navbar user={user} /> {/* Pass the user state to Navbar */}
        <ContentWrapper>
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/ContactForm" element={user ? <ContactForm /> : <Navigate to="/login" replace />} />
            <Route path="/quiz" element={user ? <Quiz /> : <Navigate to="/login" replace />} />
            <Route path="/tcseQuiz" element={user ? <TcseQuiz /> : <Navigate to="/login" replace />} />
            <Route path="/rcQuiz" element={user ? <RcQuiz /> : <Navigate to="/login" replace />} />
            <Route path="/synonymsQuiz" element={user ? <SynonymsQuiz /> : <Navigate to="/login" replace />} />
            <Route path="/vocabularyBuilder" element={user ? <VocabularyBuilder /> : <Navigate to="/login" replace />} />
            <Route path="/mathQuiz" element={user ? <MathQuizData /> : <Navigate to="/login" replace />} />
            <Route path="/satrwQuiz" element={user ? <SatrwQuiz /> : <Navigate to="/login" replace />} />
            <Route path="/satmathQuiz" element={user ? <SatMathQuiz /> : <Navigate to="/login" replace />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/disclaimer" element={<Disclaimer />} />
            <Route path="/ProfilePage" element={user ? <ProfilePage /> : <Navigate to="/login" replace />} />
            <Route path="/login" element={user ? <Navigate to="/quiz" replace /> : <Login />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>

        </ContentWrapper>
      </div>
    </Router>
  );
}

export default App;